.nav-button{
    border: none;
    background-color: #9aceff;
}

.xtable-small > :not(caption) > * > * {
    padding: 0rem .25rem;
}

/* .xtable-small tbody tr:first-child {
    background-color: #3fd5ba;
} */

.xtable-small td:nth-child(2){
    width: 50%;
}

.xtable-small td:nth-child(2),
.xtable-small td:nth-child(3){
    width: 25%;
}

.countdown-text{
    display: flex; 
    justify-content: space-between;
}

.common-table tbody tr {
    height: 33px;
}

@media only screen and (max-width: 768px) {
    #main{
        margin-top: 70px;
    }
    .footer .footer-top .footer-links img {
        height: 125px!important;
        width: 125px!important;
    }
    .nav-button{
        border: none;
        background-color: #ffffff;
        padding: 10px 20px !important;
        font-size: 15px !important;
    }   
    .table-mobile {
        width: 75%;
        font-weight: normal!important;
        color: black;
        font-size: 15px;
        font-family: "roboto";
    } 
    .table-mobile tr {
        padding: 0rem .25rem;
    } 
    .table-mobile th, .table-mobile td  {
        font-weight: normal;
        padding: 0rem .25rem;
    } 
  } 